<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <h3>{{ $t('don.npa.daily_stats_table') }}</h3>
      </div>
    </div>

    <br>

    <table class="table table-striped">
      <thead>
      </thead>
      <tbody>
      <tr v-for="(row, index) in data" :key="`item-${index}`">
        <td style="width: 10%" class="text-left">{{ row.brand }}</td>
        <td style="width: 10%" class="text-right">{{ row.inViews }} {{ $t('don.npa.of_in_views') }}</td>
        <td style="width: 10%" class="text-right">{{ row.clicks }} {{ $t('don.npa.of_clicks') }}</td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsTable',

  props: {
    data: {
      type: Array
    }
  }
}
</script>

<style lang="scss">

</style>
