<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <h3>{{ $t('don.npa.daily_stats_chart') }}</h3>
      </div>
      <div class="col-lg-6 text-right">
        <app-button-csv-export :data="getExportData()" filename="campaignProgress"></app-button-csv-export>
      </div>
    </div>

    <div style="height: 330px;">
      <app-preloader v-if="loading"></app-preloader>
      <app-line-chart
        :chart-data="analyticsChartData"
        :height="330"
        :max-labels="31"
      >
      </app-line-chart>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Preloader from '../preloader/Preloader'
import LineChart from '../chart/LineChart'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'

export default {
  name: 'AnalyticsChart',

  props: {
    loading: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },

  components: {
    appLineChart: LineChart,
    appPreloader: Preloader,
    appButtonCsvExport: ButtonCsvExport
  },

  computed: {
    analyticsChartData () {
      if (this.data === null) {
        return {
          labels: [],
          datasets: []
        }
      }

      const datasets = [
        {
          label: 'In Views ',
          borderColor: '#1693c1',
          backgroundColor: 'rgba(22, 147, 193, 0.4)',
          fill: true,
          data: this.data.inViews
        },
        {
          label: 'Clicks ',
          borderColor: '#ffb22b',
          backgroundColor: 'rgba(255, 178, 43, 0.5)',
          fill: true,
          data: this.data.clicks
        }
      ]

      return {
        labels: this.formatTimeGroupDates(this.data.timeGroups),
        datasets: datasets
      }
    }
  },

  methods: {
    getExportData () {
      const exportData = []

      if (this.data === null) {
        return exportData
      }

      const timeGroups = this.data.timeGroups

      timeGroups.forEach((timeGroup, idx) => {
        const dataRow = {
          date: moment(timeGroup).format('DD.MM. YYYY'),
          inViews: this.data.inViews[idx],
          clicks: this.data.clicks[idx]
        }

        exportData.push(dataRow)
      })

      return exportData
    },

    formatTimeGroupDates (timeGroups, format = 'DD.MM. YYYY') {
      return timeGroups.map(timeGroup => {
        return moment(timeGroup).format(format)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
